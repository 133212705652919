import { MultimediaItem } from '@/models/multimedia.model'
import {
  CompanyHousingDateAvailability,
  CompanyHousingDateAvailabilityRequest,
  EventInformation,
  EventPeriodAvailabilityPayload,
  EventTimeSlot,
  ExperienceDetailedAvailabilityPayload,
  ExperienceInformation,
  ExperienceInformationPayload,
  ExperiencePeriodAvailability,
  ExperiencePeriodAvailabilityPayload,
  ExperienceTimeslot,
  Guests,
  HousingInformation,
  PeriodAvailability,
  PeriodAvailabilityPayload,
  ProductInformation,
  PublicServiceDetails,
  RelatedService,
  ServiceContract,
  ServiceHashId,
  SimpleServiceInformation,
} from '@/models/public/service.model'
import { formatDateTime } from '@/utils/formatters'
import axios from 'axios'
import { computed, ComputedRef, Ref } from 'vue'
import { useMutation, useQuery } from 'vue-query'
import i18n from '../../i18n'

const serviceKeys = {
  all: ['publicServices'] as const,
  lists: () => [...serviceKeys.all, 'list'] as const,
  list: (serviceId: Ref<string | number | null>, locale: ComputedRef<string>) =>
    [...serviceKeys.lists(), { serviceId, locale }] as const,
  detail: (id: Ref<string | number | null>, isPreview: boolean) =>
    [...serviceKeys.all, 'detail', id, isPreview] as const,
  simpleServiceInformation: (
    id: Ref<string | number | null>,
    isPreview: boolean,
  ) => [...serviceKeys.all, 'simpleServiceInformation', id, isPreview] as const,
  mediaFile: (id: Ref<string | number | null>, isPreview: boolean) =>
    [...serviceKeys.all, 'mediaFile', id, isPreview] as const,
  additionalMediaFile: (id: number, isPreview: boolean) =>
    [...serviceKeys.all, 'mediaFile', id, isPreview] as const,
  experiencePeriodAvailabilityPeriod: (
    serviceHashId: Ref<string | null>,
    payload: Ref<ExperiencePeriodAvailabilityPayload>,
  ) => [
    ...serviceKeys.all,
    'experiencePeriodAvailabilityPeriod',
    { serviceHashId, payload },
  ],
  eventPeriodAvailabilityPeriod: (
    serviceHashId: Ref<string | null>,
    payload: Ref<EventPeriodAvailabilityPayload>,
  ) => [
    ...serviceKeys.all,
    'eventPeriodAvailabilityPeriod',
    { serviceHashId, payload },
  ],
  companyHousingDateAvailabilityList: (
    payload: Ref<CompanyHousingDateAvailabilityRequest>,
  ) => [...serviceKeys.all, 'companyHousingDateAvailabilityList', { payload }],
}

export function useServiceQuery(
  serviceId: Ref<string | number | null>,
  isPreview: boolean,
) {
  return useMutation(serviceKeys.detail(serviceId, isPreview), async () => {
    // Preview endpoint is for service provider user usage in service preview
    const serviceUrl = isPreview
      ? `public/service/${serviceId.value}/preview/`
      : `public/service/${serviceId.value}/`

    const { data } = await axios.get<PublicServiceDetails>(serviceUrl)
    return data
  })
}

export function useServiceByIdQuery(serviceId: Ref<string | number | null>) {
  return useQuery(serviceKeys.detail(serviceId, false), async () => {
    const { data } = await axios.get<PublicServiceDetails>(
      `public/service/${serviceId.value}/`,
    )
    return data
  })
}

export function useServiceRelatedServicesQuery(
  serviceId: Ref<string | number | null>,
  isPreview: boolean,
) {
  const localeRef: any = i18n.global.locale
  return useQuery(
    serviceKeys.list(serviceId, localeRef),
    async () => {
      // Preview endpoint is for service provider user usage in service preview
      // this query returns other housing type services provided by this company
      const companyHousingUnitsUrl = isPreview
        ? `public/service/${serviceId.value}/related-services/preview/`
        : `public/service/${serviceId.value}/related-services/`

      const { data } = await axios.get<RelatedService[]>(companyHousingUnitsUrl)
      return data
    },
    {
      enabled: computed(() => !!serviceId.value),
      retry: 0,
    },
  )
}

export function useCartRelatedServicesQuery(orderUuid: Ref<string | null>) {
  const localeRef: any = i18n.global.locale
  return useQuery(
    serviceKeys.list(orderUuid, localeRef),
    async () => {
      // this query returns other housing type services provided by this company
      const { data } = await axios.get<RelatedService[]>(
        `public/order/${orderUuid.value}/related-services/`,
      )
      return data
    },
    {
      enabled: computed(() => !!orderUuid.value),
      retry: 0,
    },
  )
}

export function useFetchServiceEventInformationMutation(
  hash: string,
  isPreview: boolean,
  salesItemQuantities: Record<string, number>,
  additionalServiceQuantities: Record<string, number>,
  groupService: ServiceHashId | undefined,
) {
  return useMutation(async (eventId: number) => {
    // Preview endpoint is for service provider user usage in service preview
    const serviceUrl = isPreview
      ? `public/service/${hash}/event-information/${eventId}/preview/`
      : `public/service/${hash}/event-information/${eventId}/`

    const { data } = await axios.post<EventInformation>(serviceUrl, {
      sales_items: salesItemQuantities,
      additional_services: additionalServiceQuantities,
      group_service: groupService,
    })
    return data
  })
}

export function useFetchServiceEventInformationBookingWidgetMutation(
  hash: string,
) {
  return useMutation(
    async ({
      eventId,
      sales_items,
    }: {
      eventId: number
      sales_items: Record<string, number>
    }) => {
      const { data } = await axios.post<EventInformation>(
        `public/service/${hash}/event-information/${eventId}/`,
        {
          sales_items,
          additional_services: {},
        },
      )
      return data
    },
  )
}

export function useFetchServiceHousingInformationMutation() {
  return useMutation(
    async (payload: {
      serviceId: ServiceHashId | number
      group_service: ServiceHashId | undefined
      isPreview: boolean
      check_in: string
      check_out: string
      additional_services: Record<string, number> // Record<id, quantity>
      guests: Guests[]
    }) => {
      const { serviceId, isPreview, ..._payload } = payload
      // Preview endpoint is for service provider user usage in service preview
      const url = isPreview
        ? `public/service/${serviceId}/housing-information/preview/`
        : `public/service/${serviceId}/housing-information/`

      const { data } = await axios.post<HousingInformation>(url, _payload)
      return data
    },
  )
}

export function useFetchServiceHousingInformationBookingWidgetMutation() {
  return useMutation(
    async (payload: {
      serviceHash: string
      guests: Guests[]
      check_in: string
      check_out: string
    }) => {
      const url = `public/service/${payload.serviceHash}/housing-information/`
      // @ts-ignore
      delete payload.serviceHash
      const { data } = await axios.post<HousingInformation>(url, {
        ...payload,
        additional_services: {},
      })
      return data
    },
  )
}

export function useFetchServiceExperienceInformationMutation(
  serviceId: string | number | undefined,
) {
  return useMutation(async (payload: ExperienceInformationPayload) => {
    const { data } = await axios.post<ExperienceInformation>(
      `public/service/${serviceId}/experience-information/`,
      { ...payload },
    )
    return data
  })
}

export function useQueryCompanyHousingDateAvailabilityList(
  payload: Ref<CompanyHousingDateAvailabilityRequest>,
) {
  return useQuery(
    serviceKeys.companyHousingDateAvailabilityList(payload),
    async () => {
      const { data } = await axios.post<CompanyHousingDateAvailability[]>(
        `/public/housings/dates-availability/`,
        {
          ...payload.value,
          // Undefined because it has user friendlies error message compared to empty string and null (for dates)
          start: payload.value.start || undefined,
          end: payload.value.end || undefined,
        },
      )
      return data
    },
    {
      enabled: computed(
        () =>
          !!payload.value.company &&
          !!payload.value.start &&
          !!payload.value.end,
      ),
    },
  )
}

export function useFetchCompanyPeriodAvailability() {
  return useMutation(async (payload: PeriodAvailabilityPayload) => {
    const { data } = await axios.post<PeriodAvailability[]>(
      `/public/housings/period-availability/`,
      {
        ...payload,
        // Undefined because it has user friendlies error message compared to empty string and null (for dates)
        start: payload.start || undefined,
        end: payload.end || undefined,
      },
    )
    return data
  })
}

export function useQueryEventPeriodAvailability(
  serviceHashId: Ref<string | null>,
  payload: Ref<EventPeriodAvailabilityPayload>,
) {
  return useQuery(
    serviceKeys.eventPeriodAvailabilityPeriod(serviceHashId, payload),
    async () => {
      const { data } = await axios.post<Date[]>(
        `/public/service/${serviceHashId.value}/event-availability/`,
        {
          ...payload.value,
          start:
            formatDateTime(payload.value?.start, {
              format: 'yyyy-MM-dd',
            }) || undefined,
          end:
            formatDateTime(payload.value?.end, {
              format: 'yyyy-MM-dd',
            }) || undefined,
        },
      )
      return data
    },
    {
      enabled: computed(
        () =>
          !!serviceHashId.value &&
          !!payload.value?.start &&
          !!payload.value?.end,
      ),
      retry: 0,
    },
  )
}

export function useFetchExperiencePeriodAvailability(
  serviceHashId: string | number | undefined,
) {
  return useMutation(async (payload: ExperiencePeriodAvailabilityPayload) => {
    const { data } = await axios.post<ExperiencePeriodAvailability[]>(
      `/public/service/${serviceHashId}/experience-availability/`,
      {
        ...payload,
        start:
          formatDateTime(payload.start, {
            format: 'yyyy-MM-dd',
          }) || undefined,
        end:
          formatDateTime(payload.end, {
            format: 'yyyy-MM-dd',
          }) || undefined,
      },
    )
    return data
  })
}

export function useFetchExperienceDetailedAvailability(
  serviceId: string | number | undefined,
) {
  return useMutation(async (payload: ExperienceDetailedAvailabilityPayload) => {
    const { data } = await axios.post<ExperienceTimeslot[]>(
      `/public/service/${serviceId}/experience-detailed-availability/`,
      {
        ...payload,
        date: formatDateTime(payload.date, { format: 'yyyy-MM-dd' }),
      },
    )
    return data
  })
}

export function fetchExperiencePeriodAvailability(
  payload: ExperiencePeriodAvailabilityPayload,
) {
  return payload
}

export function useFetchEventDetailedAvailability(
  serviceId: Ref<string | number | undefined>,
) {
  return useMutation(async (date: string | Date) => {
    const { data } = await axios.post<EventTimeSlot[]>(
      `/public/service/${serviceId.value}/event-detailed-availability/`,
      {
        date: formatDateTime(date, { format: 'yyyy-MM-dd' }),
      },
    )
    return data
  })
}

export function useServiceMediaFileQuery(
  serviceId: Ref<string | number | null>,
  isPreview: boolean,
) {
  return useQuery(
    serviceKeys.mediaFile(serviceId, isPreview),
    async () => {
      // Preview endpoint is for service provider user usage in service preview
      const multimediaUrl = isPreview
        ? `public/service/${serviceId.value}/media-files/preview/`
        : `public/service/${serviceId.value}/media-files/`
      const { data } = await axios.get<MultimediaItem[]>(multimediaUrl)
      return data
    },
    {
      enabled: computed(() => !!serviceId.value),
      retry: 0,
    },
  )
}

export function useAdditionalServiceMediaFileQuery(
  serviceId: number,
  isPreview: boolean,
) {
  return useQuery(
    serviceKeys.additionalMediaFile(serviceId, isPreview),
    async () => {
      // Preview endpoint is for service provider user usage in service preview
      const multimediaUrl = isPreview
        ? `public/additional-service/${serviceId}/media-files/preview/`
        : `public/additional-service/${serviceId}/media-files/`
      const { data } = await axios.get<MultimediaItem[]>(multimediaUrl)
      return data
    },
    {
      enabled: computed(() => !!serviceId),
      retry: 0,
    },
  )
}

export function useHousingUnitMediaFileQuery(
  serviceId: number,
  isPreview: boolean,
) {
  return useQuery(
    serviceKeys.additionalMediaFile(serviceId, isPreview),
    async () => {
      // Preview endpoint is for service provider user usage in service preview
      const multimediaUrl = isPreview
        ? `public/housing/${serviceId}/media-files/preview/`
        : `public/housing/${serviceId}/media-files/`
      const { data } = await axios.get<MultimediaItem[]>(multimediaUrl)
      return data
    },
    {
      enabled: computed(() => !!serviceId),
      retry: 0,
    },
  )
}

export function useFetchSimpleServiceInformationMutation(
  serviceId: Ref<ServiceHashId | number | null>,
  isPreview: boolean,
  salesItemQuantities: Record<string, string>,
  orderItem: Record<string, number | null>,
  groupService: ServiceHashId | undefined,
) {
  return useMutation(async () => {
    // Preview endpoint is for service provider in service preview
    const serviceUrl = isPreview
      ? `public/service/${serviceId.value}/simple-service-information/preview/`
      : `public/service/${serviceId.value}/simple-service-information/`

    const { data } = await axios.post<SimpleServiceInformation>(serviceUrl, {
      sales_items: salesItemQuantities,
      order_item: orderItem.id,
      group_service: groupService,
    })
    return data
  })
}

export function useFetchServiceProductInformationMutation(
  serviceId: Ref<ServiceHashId | number | null>,
  isPreview: boolean,
) {
  return useMutation(
    async (payload: {
      quantity: number
      group_service: ServiceHashId | undefined
    }) => {
      // Preview endpoint is for service provider in service preview
      const serviceUrl = isPreview
        ? `public/service/${serviceId.value}/product-information/preview/`
        : `public/service/${serviceId.value}/product-information/`

      const { data } = await axios.post<ProductInformation>(serviceUrl, payload)
      return data
    },
  )
}

export function useServiceContractQuery(serviceId: Ref<string | null>) {
  const localeRef: any = i18n.global.locale
  return useQuery(
    serviceKeys.list(serviceId, localeRef),
    async () => {
      const { data } = await axios.get<ServiceContract>(
        `public/service/${serviceId.value}/contract/`,
      )
      return data
    },
    {
      enabled: computed(() => !!serviceId.value),
      retry: 0,
    },
  )
}
